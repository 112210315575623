import 'components/weather-icon'
import { WindIcon } from 'components/wind-icon'
import 'components/weather-icon'
import initSwitchUnits from 'components/switch-units'
import { TempValue } from 'components/temp-value'
import init from 'init/application'
import sticky from 'components/sticky'
import scrollFade from 'components/scroll-fade'
import ForecastTable from 'components/forecast-table'
import { ForecastTableElevation } from 'components/forecast-table-elevation'
import 'components/location_summary/location_summary'
import 'components/issued/issued'
import 'components/stories/stories'
import isBnInViewport from 'utils/ad-refresh'
import 'components/latlong/latlong'
import 'components/panorama/panorama'
import 'components/app-store'
import 'components/store-badges'
import { Smartbar } from 'components/smartbar'
import tshirts from 'components/tshirts/tshirts'
import gallerySortLinks from 'components/gallery-sort-links/gallery_sort_links'
import initTooltip from 'components/tooltips'
import 'components/gallery-photo-upload/gallery_photo_upload'
import 'components/h1-title'
import { ScrollContainer } from 'components/scroll-container'
import { Leaflet } from 'components/leaflet'
import { SnowAmount } from 'components/snow-amount'
import 'components/bike-park-info'
import 'components/bike-park-header'
import { Banner } from 'components/questionnaire-banner'

import './polyfill'

// eslint-disable-next-line import/first, import/no-unresolved
import 'fc-ads/fc-ads.scss'

require.context('static', true, /\.(png|jpg|svg)$/)

window.WindIcon = WindIcon
window.TempValue = TempValue

function onDOMLoad() {
  init()
  scrollFade()
  WindIcon.init(document)
  TempValue.init(document)
  Banner.init(document.body)
}

function onWindowLoad() {
  initSwitchUnits()
  ForecastTable()
  tshirts()
  gallerySortLinks()
  initTooltip()
  sticky()

  ScrollContainer.init(document.body)
  SnowAmount.init(document.body)
  Smartbar.init(document.body)
  ForecastTableElevation.init(document.body)

  if (window.FCGON && window.FCGON.maps) {
    Leaflet.init(document.body, window.FCGON.maps)
  }
}

window.isBnInViewport = isBnInViewport

// `DOMContentLoaded` may fire before your script has a chance to onDOMLoad, so check before adding a listener
if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', onDOMLoad)
} else {
  onDOMLoad()
}

if (document.readyState === 'loading' || document.readyState === 'interactive') {
  window.addEventListener('load', onWindowLoad)
} else {
  onWindowLoad()
}
